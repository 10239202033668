.container {
    list-style-type: none;
    padding: 0;
    width: 100%;
}

.container .item {
    padding: 1.5rem;
    background-color: #3e4550;
    box-shadow: 0 0 2rem #2b2b2b;
    border-radius: 1rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
}

.container .item a {
    color: white;
    text-decoration: none;
}
