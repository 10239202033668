* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  padding-top: 6.5rem;
  background-color: #282c34;
}

.App-section {
  min-height: calc(100dvh - 65px);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 3rem 2rem;
}

.App-section input:not([type="checkbox"]) {
  width: 100%;
  margin-bottom: 2rem;
  padding: 1rem;
  font-size: 1.6rem;
  border: 0;
  border-radius: 0.5rem;
  box-shadow: 0 0 2rem #333;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-section .App-admin-logo {
  margin: 0;
  text-align: center;
}

.App-section .App-admin-logo img {
  width: 50%;
}

.App-link {
  color: #61dafb;
}

.btn {
  text-decoration: none;
  text-align: center;
  padding: 1rem 4rem;
  color: white;
  border-radius: 1rem;
  border: 0;
  background-color: #282c34;
  font-size: 1.6rem;
  cursor: pointer;
}

.btn-lg {
  padding: 1.5rem 6rem;
  font-size: 2rem;
}

.btn-block {
  width: 100%;
  margin-bottom: 3rem;
}

.btn-default {
  background-color: #2285ff;
}

.btn-success {
  background-color: #009F3C;
}

.btn-warning {
  background-color: #EC6733;
}

.btn-danger {
  background-color: #ec3333;
}

.text-warning {
  color: #fca000;
}

.App-section .container {
  display: flex;
}

.App-section .container figure {
  margin: 1.6rem;
}

.App-section figure img {
  width: 100%;
}

.App-section .container figure.disabled img {
  filter: grayscale(1);
}

#customersList {
  padding: 0.8rem;
  width: 80%;
  font-size: 1.6rem;
}

.App-company {
  width: 75%;
  margin: 0;
}

.App-phone {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.App-phone-icon {
  width: 7rem;
  margin: 0;
}

.App-phone-icon img {
  width: 100%;
}

.task {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: left;
  margin-bottom: 0.5rem;
}

.task input, .form-group input[type="checkbox"] {
  margin-right: 2rem;
}

.task label {
  display: block;
}

.task label::first-letter {
  text-transform: uppercase;
}

.task label.complete {
  text-decoration: line-through;
}

.unusual-tasks {
  background-color: #282c34;
  border-radius: 1rem;
  padding: 2rem;
  margin-top: 2rem;
}

.unusual-tasks-label {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.unusual-tasks-label img {
  width: 4rem;
  margin-right: 2rem;
}

.unusual-tasks-label p {
  text-align: left;
  margin: 0;
}

.App-comment {
  width: 100%;
  padding: 2rem 0;
  margin-top: 2rem;
}

.App-comment textarea {
  width: 100%;
  padding: 1.5rem;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.6rem;
}

.App-comment .inputfile {
	display: none;
}

.App-comment label {
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  margin-top: 1rem;
}

.App-comment .inputfile:focus + label {
  outline: 1px dotted #000;
	outline: -webkit-focus-ring-color auto 5px;
}

.App-comment .inputfile + label * {
	pointer-events: none;
}

.App-comment .inputfile + label img {
	width: 3rem;
}

.react-calendar {
  width: 100% !important;
  color: black;
}

.react-calendar + .App-comment {
  padding: 0;
}

.bottom {
  margin-top: auto;
  margin-bottom: 0;
}

.App-admin {
  text-align: left;
  align-items: flex-start;
}

.delivery-days {
  width: 100%;
  margin-bottom: 4rem;
}

.switch-group {
  padding: 0.75rem 0;
}

.switch-group span {
  margin-left: 1.25rem;
}

.state-customer {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

#customerName {
  margin-top: 0.75rem;
}

.App-panel {
  width: 100%;
  padding: 2rem;
  background-color: #343a44;
  border-radius: 1rem;
  margin-bottom: 2rem;
  box-shadow: 0 0 2rem #2b2b2b;
}

.App-panel-warning {
  border: 0.5rem solid #b1750f;
}

.App-panel-warning h2 {
  font-size: 2rem;
}

.App-panel h1, .App-panel h2 {
  margin-top: 0;
}

.task-customer {
  display: flex;
  align-items: center;
  padding: 1rem;
  margin: 1rem 0;
  background-color: #454d5a;
  border-radius: 1rem;
}

.task-customer img {
  width: 2.5rem;
  margin-right: 1rem;
}

.add-task {
  display: flex;
  justify-content: space-between;
  margin-top: 2.5rem;
}

.add-task select {
  width: 65%;
  font-size: 1.6rem;
  padding: 0.5rem;
  border-radius: 1rem;
}

.add-task .btn {
  width: 30%;
  padding: 1rem;
}