.switch {
    margin: 0;
    padding: 0;
    color: rgba(0,0,0,.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    position: relative;
    display: inline-block;
    min-width: 52px;
    height: 26px;
    line-height: 26px;
    vertical-align: middle;
    background-color: rgba(255, 255, 255, 0.5);
    border: 0;
    border-radius: 100px;
    cursor: pointer;
    transition: all .36s;
    user-select: none;
}

.switch-checked {
    background-color: #009f3d;
}

.switch-handle::before {
    position: absolute;
    top: 2px;
    right: 28px;
    bottom: 2px;
    left: 2px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 2px 4px 0 rgba(0,35,11,.2);
    transition: all .36s cubic-bezier(.78,.14,.15,.86);
    content: '';
}

.switch-checked .switch-handle::before {
    right: 2px;
    left: 28px;
}

.switch-inner {
    display: block;
    margin: 0 7px 0 25px;
    color: #fff;
    font-size: 12px;
    transition: margin .36s;
}

.switch-checked .switch-inner {
    margin: 0 25px 0 7px;
}