.container {
    background-color: #282c34;
    display: flex;
    align-items: center;
    padding: 1rem 2rem;
    box-shadow: 0 1rem 1rem #111;
}

.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}

.container .previous {
    width: 30px;
    cursor: pointer;
    margin-right: 2rem;
}

.container .logo {
    width: 60px;
}